@import "../../_globalColor";

.contact-margin-top {
  margin-top: 4rem;
}

.contact-div-main {
  display: flex;
}

.dark-mode {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
}
.contact-info-div {
  display: flex;
  flex-direction: column;
}

.contact-info-div > * + * {
  margin-top: 1rem;
}

.contact-info-div > div:last-child {
  margin-top: 2rem;
}

.contact-title {
  margin: 0;
  font-size: 40px;
  font-weight: 400;
}

.contact-subtitle {
  text-transform: uppercase;
  margin-bottom: 0;
}

.contact-phone {
  color: $textColor;
  font-size: 1.5rem;
  font-weight: 400;
  text-decoration: none;
}

.dark-mode .contact-phone {
  color: $textColorDark;
}

.contact-phone:hover {
  text-shadow: 1px 1px 2px $contactDetailHoverTS;
  transition: all 0.3s;
}

.contact-socials {
  margin-top: 2rem;
}

.contact-image-div > img {
  max-width: 100%;
  height: auto;
  margin-left: 1.5rem;
  margin-top: -4rem;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }

  .contact-title {
    font-size: 40px;
    text-align: center;
  }

  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }

  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }

  .contact-phone {
    font-size: 20px;
  }

  .contact-image-div {
    display: none;
  }
}
